// Import necessary React components and hooks
import React, { useEffect, useState, useCallback } from 'react'

// Import BDC UI components
import {
  Button,
  Box,
  DataGrid,
  Stack,
  Alert as BDCAlert,
  Snackbar,
  CircularProgress
} from 'connected-react-components';

// Import the API helper from the 'connected-sdk-js' package
import { API } from 'connected-sdk-js'

/**
 * Alert component to display error messages.
 * @param {Object} props - Component props.
 * @param {string} props.title - The message to display in the Alert.
 * @param {function} props.onClose - The function to call when the Alert is closed.
 * @param {string} props.severity - The severity of the Alert (error, warning, info, success).
 * @param {Object} ref - The ref object to forward.
 * @returns {React.Element} - The rendered Alert component.
 */
const Alert = React.forwardRef(function Alert(props, ref) {
  // Pass the forwarded ref and other props to the BDCAlert component
  return <BDCAlert innerRef={ref} {...props} />
})

/**
 * Home component to display rows data.
 * @component
 * @param {Object} props - Component props.
 * @returns {React.Element} - The rendered Home component.
 */
export default function Home(props) {
  // Declare state variables to store selected row IDs, all rows, Snackbar open state, and alert message
  const [selectedRows, setSelectedRows] = useState([])
  const [rows, setRows] = useState(null)
  const [open, setOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [currentAddId, setCurrentAddId] = useState(null)
  const [globalError, setGlobalError] = useState(null)

  /**
   * Function to handle Snackbar close event.
   * @param {Object} event - The event object.
   * @param {string} reason - The reason the Snackbar was closed.
   */
  const handleClose = (event, reason) => {
    // If the Snackbar was closed by clicking outside, do nothing
    if (reason === 'clickaway') {
      return
    }

    // Otherwise, set the open state to false to hide the Snackbar
    setOpen(false)
  }

  /**
   * Event handler for DataGrid row selection change event.
   * @param {Array} model - The array of selected row IDs.
   * @param {Object} details - The event details object.
   */
  const onRowSelectionModelChange = useCallback((model, details) => {
    // Update the selectedRows state with the new model (array of selected row IDs)
    setSelectedRows(model)
  }, [])

  /**
   * Async function to handle row deletion.
   * @returns {Array} - The updated rows array.
   */
  const handleDeleteRow = useCallback(async () => {
    try {
      // If no row is selected, show an alert and return early
      if (selectedRows.length === 0) {
        setAlertMessage('Please select a row to delete.')
        setOpen(true)
        return setRows(rows)
      }

      const selectedRow = rows.find((row) => {
        return row.id === selectedRows[0]
      })

      // Make the API request to delete the row with the selected ID
      await API.delete(`/api/${selectedRow.tenantid}`)

      // Filter out the deleted row from the state
      const newRows = rows.filter((row) => {
        return row.id !== selectedRows[0]
      })

      // Update the rows state with the new rows
      return setRows(newRows)
    } catch (error) {
      // TODO: Add logging
      setGlobalError(
        'There was an issue with the application deleting the row. Please refresh and/or try again later.'
      )
      return rows
    }
  }, [selectedRows, rows])

  // Async function to handle row update
  const processRowUpdate = useCallback(
    async (params) => {
      try {
        if (params.id === currentAddId) {
          setCurrentAddId(null)
          // Make the API request to create the row with the provided data
          await API.post(`/api/`, null, {
            tenantid: params.tenantid,
            url: params.url
          })
        } else {
          // Make the API request to update the row with the provided data
          await API.put(`/api/${params.tenantid}`, null, {
            url: params.url
          })
        }

        // Return the updated row params
        return params
      } catch (error) {
        // TODO: Add logging
        setGlobalError(
          'There was an issue with the application creating/updating the row. Please refresh and/or try again later.'
        )
        return params
      }
    },
    [currentAddId]
  )

  // Function to handle adding a new row
  const handleAddRow = useCallback(() => {
    let maxId = 0

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].id > maxId) {
        maxId = rows[i].id
      }
    }

    if (maxId === currentAddId) {
      setAlertMessage(
        'Only one new row can be added at a time. Please save or delete the current new row before adding a new one.'
      )
      setOpen(true)
      return
    }

    const newId = maxId + 1

    setCurrentAddId(newId)

    // Define a placeholder row to be added
    const placeholderRow = {
      id: newId,
      tenantid: '00000000-0000-0000-0000-000000000000',
      url: 'https://www.changeme.com'
    }

    // Add the placeholder row to the beginning of the rows state
    setRows((prevRows) => [placeholderRow, ...prevRows])
  }, [rows, currentAddId])

  // Effect hook to fetch rows data from the API when the component mounts
  useEffect(() => {
    ;(async () => {
      try {
        // Fetch data from the API
        let response = await API.get('/api/')
        let idC = 1
        // Map the API response data to the row data format
        const rows = response.data.map((row) => {
          idC++
          return {
            id: idC,
            ...row
          }
        })
        // Update the rows state with the fetched data
        setRows(rows)
      } catch (exception) {
        // TODO: Add logging
        setGlobalError(
          'There was an issue with the application. Please refresh and/or try again later.'
        )
      }
    })()
  }, [])

  const isCellEditable = useCallback(
    (params) => {
      if (params.field === 'tenantid') {
        if (params.id === currentAddId) {
          // if the row is being added, tenantid is editable
          return true
        } else {
          // otherwise, tenantid is not editable
          return false
        }
      }
      // all other fields are editable
      return true
    },
    [currentAddId]
  )

  if (globalError)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh'
        }}
      >
        <Alert
          title={globalError}
          severity='error'
          sx={{
            width: '100%',
            height: '125px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      </Box>
    )

  // Render a loading message while rows data is being fetched
  if (!rows)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh'
        }}
      >
        <CircularProgress />
      </Box>
    )

  // Render the Home component UI
  return (
    <Box sx={{}}>
      <Stack direction='row' justifyContent='flex-end'>
        <Button size='small' onClick={handleDeleteRow}>
          Delete a row
        </Button>
        <Button size='small' onClick={handleAddRow}>
          Add a row
        </Button>
      </Stack>
      <DataGrid
        hideFooterPagination={true}
        width={'100%'}
        autoHeight={true}
        editMode={'row'}
        processRowUpdate={processRowUpdate}
        onRowSelectionModelChange={onRowSelectionModelChange}
        isCellEditable={isCellEditable}
        columns={[
          {
            width: 350,
            field: 'tenantid',
            headerName: 'Tenant ID',
            editable: true
          },
          {
            width: 350,
            field: 'url',
            headerName: 'URL',
            editable: true
          }
        ]}
        rows={rows}
      />

      <Snackbar open={open} onClose={handleClose}>
        <Alert
          title={alertMessage}
          onClose={handleClose}
          severity='error'
          sx={{ width: '100%' }}
        />
      </Snackbar>
    </Box>
  )
}
