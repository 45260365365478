export default {
            Auth: {
                region: 'us-east-1',
                userPoolId: 'us-east-1_ygOLYX78x',
                userPoolWebClientId: '7g61aecfjkefi9e0ip1coa7plk',
                oauth: {
                    domain: 'cerner-ehr-mapping.auth.us-east-1.amazoncognito.com',
                    scope: ['email', 'profile', 'openid'],
                    redirectSignIn: 'https://ehr-mapping-ui.dev.bddevops.com',
                    redirectSignOut: 'https://ehr-mapping-ui.dev.bddevops.com',
                    responseType: 'code',
                    options: {
                        AdvancedSecurityDataCollectionFlag: false
                    }
                }
            }
        }