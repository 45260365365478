import React, { useEffect, useState, useReducer } from 'react'
import { Shell } from 'connected-react-components'
import RouteMap from './Routes'
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports)


function reducer(state, action) {
  switch (action.type) {
    case 'increment':
      return { count: state.count + 1 }
    case 'decrement':
      return { count: state.count - 1 }
    default:
      throw new Error()
  }
}

export default function App(props) {
  // For tracking all app module shared state not specific to user workflows
  const [state, setState] = useReducer(reducer, { count: 1 })
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const stateUpdate = (input) => {
    setState(input)
  }

  const nav = {
    type: 'top',
    links: [
    ]
  }

  // Check if the user is already authenticated
  const handleAuth = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser()
      if (authUser) {
        setUser(authUser)
        setLoading(false)
      } else {
        Auth.federatedSignIn()
      }
    } catch (error) {
      console.log(error)
      Auth.federatedSignIn()
    }
  }

  // Handle authentication
  useEffect(() => {
    ;(async () => {
      await handleAuth()
    })()
  }, [])


  if (loading) {
    return <div>Loading...</div>
  }

  // If the user isn't authenticated, call Auth.federatedSignIn()
  if (!user) {
    Auth.federatedSignIn()
  }

  return (
    <Shell
      title='EHR Mapping'
      nav={nav}
      navigate={props.navigate}
      breadcrumbs={false}
      settings={[
        {
          text: `Logout (${user.signInUserSession.idToken.payload.email})`,
          onClick: Auth.signOut
        }
      ]}
    >
      <RouteMap state={state} stateUpdate={stateUpdate} />
    </Shell>
  )
}
