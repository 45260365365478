import { Routes, Route } from 'react-router-dom'
import Home from './components/Home'

export default function RouteMap(props) {
  return (
    <Routes>
      <Route
        path='/'
        element={<Home state={props.state} set={props.stateUpdate} />}
      />
    </Routes>
  )
}
